'use client';
import React, { useRef } from 'react';

import { HomeHeroSectionType } from '@/app/(landing)/lib/types/sectionsType';
import LottieCard from '../Common/LottieCard';

import HomeLottie from '@/public/lottie/BackgroundLines.json';
import Image from '@/app/(landing)/components/Image';
import { VideoDialog } from '@/components/ui/video-dialog';
import { ChevronDownIcon } from 'lucide-react';
import PromptDemo from './PromptDemo';

/* const LOGO_FILE_NAMES: string[] = [
  'Square',
  'Accenture',
  'KPMG',
  'University_of_Cambridge',
  'MIT',
  'ETH_Zurich',
  // 'OpenAI',
  // 'Meta',
  // 'United_Nations',
  // 'Sherwin-Williams',
  // 'Naver',
  // 'AISI',
  // 'AXA',
  // 'METR',
]; */

const DEMO_YOUTUBE_URL = 'https://www.youtube.com/watch?v=mOwk-8Cd_qY';

const HomeHeroSection: React.FC<HomeHeroSectionType> = (block) => {
  const { title, content, cta, subTitle } = block || {};

  const nextSectionRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="mb-[70px] sm:mb-[100px] relative">
      {/* Pitch, Video, and Marquee */}
      <div className="w-full max-w-[1440px] text-center lg:min-h-[90vh] flex flex-col justify-center items-center mx-auto">
        {/* Pitch and Video */}
        <div className="flex flex-col mx-auto pt-3">
          {/* Pitch Container */}
          <div className="w-full  pt-[110px] lg:pt-[100px] pb-[60px] lg:pb-[90px] relative z-[2] flex flex-col justify-center gap-1 md:gap-5">
            {/* Pitch */}
            <div className="flex flex-col items-center justify-center gap-4 1xl:gap-7 px-5">
              <div>
                {title && subTitle && (
                  <h1
                    className="text-brand-Black1 text-[36px] em:text-[40px] sm:text-[46px] lg:text-[58px] 1xl:text-[72px] font-Mono font-extralight  [&>span]:text-brand-Orange [&>span]:font-Mono [&>span]:font-medium [&>strong]:font-medium leading-[138%] tracking-[-1.44px]"
                    dangerouslySetInnerHTML={{ __html: `${title} ${subTitle}` }}
                  />
                )}
              </div>
              {content && (
                <p
                  className="text-sm em:text-base sm:text-lg text-brand-SlateGray1 leading-[128%] max-w-[660px]"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
              {/* CTA */}
              {/* <div className="mb-3 flex flex-col lg:flex-row items-center font-bold">
                <Link to={resolveUrl(cta?.link)} className="inline-block">
                  <div className="bg-[#FF5B45] hover:bg-[#E64E3E] px-[22px] py-[7px] rounded-[28px] inline-block mt-1.5 flex items-center h-[50px] transition-colors duration-200">
                    <span className="text-[#FFF] text-base">
                      {cta?.label}
                    </span>
                  </div>
                </Link>
                <VideoDialog youtubeUrl={DEMO_YOUTUBE_URL}>
                  <div className="hidden lg:flex w-fit bg-[#E6E6E6] hover:bg-[#CFCFCF] px-[28px] py-[14px] rounded-[28px] ml-[20px] mt-1.5 items-center h-[50px] transition-colors duration-200">
                    <Image
                      src={'/images/play.svg'}
                      width={20}
                      height={20}
                      alt="play button"
                      className="mr-2"
                    />
                    <span className="text-brand-Black1 text-base">
                      How It Works
                    </span>
                  </div>
                </VideoDialog>
              </div> */}
              <PromptDemo />
            </div>
          </div>

          {/* Trusted */}
          {/* <div className="text-center z-[1]">
            <p className="text-brand-SlateGray text-md font-regular mb-4 mx-4 pb-2">
              Built by
              <a href="https://www.weco.ai" target="_blank">
                <Image
                  src={`/images/logos/Weco.png`}
                  alt={`Weco AI logo`}
                  width={150}
                  height={50}
                  className="inline-block w-[47px] mx-1 transition-opacity duration-150 opacity-90 hover:opacity-100"
                  style={{ verticalAlign: '-17%' }}
                />
              </a>
              - trusted by innovative teams at leading organizations:
            </p>
            <div className="mx-auto flex flex-wrap justify-center gap-8 px-4 pb-8 max-w-[1000px]">
              {LOGO_FILE_NAMES.map((company, index) => (
                <div key={index} className="flex items-center w-[24%] lg:w-[13%]">
                  <Image
                    src={`/images/logos/${company}.png`}
                    alt={`${index + 1} logo`}
                    width={150}
                    height={50}
                    className="grayscale opacity-50 mx-auto"
                  />
                </div>
              ))}
            </div>
          </div> */}

          {/* Video */}
          <div className="w-full pt-[0] lg:pt-[100px] pb-[80px] lg:pb-[90px] p-5 lg:p-14 relative z-[2] flex flex-col justify-center items-center">
            <VideoDialog youtubeUrl={DEMO_YOUTUBE_URL}>
              <div className="bg-gray-300 flex justify-center items-center text-center aspect-[16/9] rounded-2xl overflow-hidden group shadow-[0_24px_50px_#00000040] relative w-full max-w-[840px]">
                <video
                  className="w-full h-full object-cover"
                  loop
                  autoPlay
                  muted
                  playsInline
                  preload="auto"
                >
                  <source src="/videos/thumbnail.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="absolute inset-0 bg-[radial-gradient(circle,rgba(0,0,0,0.1)_20%,rgba(0,0,0,0.6)_100%)] pointer-events-none transition-opacity duration-300 ease-in-out group-hover:opacity-70" />
                <Image
                  src={'/images/playCircle.svg'}
                  width={72}
                  height={72}
                  alt="play button"
                  className="transition-transform duration-300 ease-in-out group-hover:scale-110 select-none absolute"
                />
              </div>
            </VideoDialog>
          </div>
        </div>
      </div>

      {/* Background Animation */}
      <div className="absolute inset-0">
        {HomeLottie && (
          <LottieCard
            className="w-full h-full rounded-lg [&>svg]:rounded-[10px]"
            jsonFileUrl={HomeLottie}
            loop
          />
        )}
      </div>

      {/* Scroll Icon */}
      {HomeLottie && (
        <div className="flex justify-center items-center z-[5] bottom-[10px] animate-bounce-icon p-2 pt-8">
          <div className="flex justify-center items-center w-[40px] h-[40px] 1xl:w-[50px] 1xl:h-[50px] bg-opacity-0 rounded-full">
            <ChevronDownIcon
              onClick={handleScroll}
              className="w-[30px] 1xl:w-[40px] h-[30px] 1xl:h-[40px] cursor-pointer text-zinc-400"
            />
          </div>
        </div>
      )}

      <div ref={nextSectionRef} id="nextSectionId" />
    </div>
  );
};

export default HomeHeroSection;
